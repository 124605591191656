import React, { useEffect, useState } from 'react';
import './App.css';
import './snoww.scss';
import RandomPicker from './lottery';
function App() {
  const [namesList, setJsonData] = useState(null);
  const [winnerList, setWinners] = useState([]);

  const copyToClipboard = (content) => {
    const el = document.createElement("textarea");
    el.value = content;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(el);
};

const updateWinners = (newWinner) => {
  setWinners((prevWinners) => {
    const updatedWinners = [...prevWinners, newWinner];
    copyToClipboard(JSON.stringify(updatedWinners));
    return updatedWinners;
  });
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('Overall.json'); // Adjust the path accordingly
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    const fetchData2 = async () => {
      try {
        const response = await fetch('Overall_wins.json'); // Adjust the path accordingly
        const data = await response.json();
        setWinners(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
    fetchData2();
  }, []);
  return (
    <div className="App">
      {(() => {
        let snow = [];
        for(let i = 0; i < 30; i++){
          snow.push(<div class="snow"></div>);
        }
        return snow;
      })()}
      {(() => {
        let snow2 = [];
        for(let i = 0; i < 30; i++){
          snow2.push(<div class="snow2"></div>);
        }
        return snow2;
      })()}

      <RandomPicker items={namesList} winners={winnerList} updateWinners={updateWinners}/>
    </div>
  );
}

export default App;
