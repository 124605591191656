import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class RandomPicker extends PureComponent {
  constructor() {
    super();

    this.state = {
      isRunning: false,
      currentChoice: {},
    };

    this.interval = null;
    this.intervalDuration = 25;
    this.duration = 1000;

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.reset = this.reset.bind(this);
    this.pickChoice = this.pickChoice.bind(this);
    this.setChoice = this.setChoice.bind(this);
  }

  start() {
    clearInterval(this.interval);
    this.interval = setInterval(this.setChoice, 25);
    this.setState({ isRunning: true });
    setTimeout(() => {
      if (this.state.isRunning) {
        this.stop();
        this.interval = setInterval(this.setChoice, 50);
        this.setState({ isRunning: true });
        setTimeout(() => {
          if (this.state.isRunning) {
            this.stop();
            this.interval = setInterval(this.setChoice, 100);
            this.setState({ isRunning: true });
            setTimeout(() => {
              if (this.state.isRunning) {
                this.stop();
                this.interval = setInterval(this.setChoice, 125);
                this.setState({ isRunning: true });
                setTimeout(() => {
                  if (this.state.isRunning) {
                    this.stop();
                    this.interval = setInterval(this.setChoice, 150);
                    this.setState({ isRunning: true });
                    setTimeout(() => {
                      if (this.state.isRunning) {
                        this.stop();
                        this.interval = setInterval(this.setChoice, 175);
                        this.setState({ isRunning: true });
                        setTimeout(() => {
                          if (this.state.isRunning) {
                            this.stop();
                            this.interval = setInterval(this.setChoice, 200);
                            this.setState({ isRunning: true });
                            setTimeout(() => {
                              if (this.state.isRunning) {
                                this.stop();
                                this.interval = setInterval(
                                  this.setChoice,
                                  225
                                );
                                this.setState({ isRunning: true });
                                setTimeout(() => {
                                  if (this.state.isRunning) {
                                    this.stop();
                                    this.interval = setInterval(
                                      this.setChoice,
                                      250
                                    );
                                    this.setState({ isRunning: true });
                                    setTimeout(() => {
                                      if (this.state.isRunning) {
                                        this.stop();
                                        this.interval = setInterval(
                                          this.setChoice,
                                          275
                                        );
                                        this.setState({ isRunning: true });
                                        setTimeout(() => {
                                          if (this.state.isRunning) {
                                            this.stop();
                                            this.interval = setInterval(
                                              this.setChoice,
                                              300
                                            );
                                            this.setState({ isRunning: true });
                                            setTimeout(() => {
                                              if (this.state.isRunning) {
                                                this.stop();
                                              }
                                            }, 500);
                                          }
                                        }, 500);
                                      }
                                    }, 500);
                                  }
                                }, 500);
                              }
                            }, 500);
                          }
                        }, 500);
                      }
                    }, 500);
                  }
                }, 500);
              }
            }, 500);
          }
        }, 500);
      }
    }, 500);
  }

  startb() {
    clearInterval(this.interval);

    // Initial interval duration
    this.intervalDuration = 25;

    // Step 1: Interval = 25 for the first 3 seconds
    this.interval = setInterval(() => {
      this.setChoice();
    }, this.intervalDuration);

    // Step 2: Change interval to 250 after 2 seconds
    setTimeout(() => {
      clearInterval(this.interval);
      this.intervalDuration = 250;
      this.interval = setInterval(() => {
        this.setChoice();
      }, this.intervalDuration);
    }, 3000);

    // Step 3: Change interval to 500 after 2 seconds
    setTimeout(() => {
      clearInterval(this.interval);
      this.intervalDuration = 500;
      this.interval = setInterval(() => {
        this.setChoice();
      }, this.intervalDuration);
    }, 5000);

    // Step 4: Change interval to 750 after 1 second
    setTimeout(() => {
      clearInterval(this.interval);
      this.intervalDuration = 750;
      this.interval = setInterval(() => {
        this.setChoice();
      }, this.intervalDuration);
    }, 7000);

    // Step 5: Change interval to 1000 after 1 second
    setTimeout(() => {
      clearInterval(this.interval);
      this.intervalDuration = 1000;
      this.interval = setInterval(() => {
        this.setChoice();
      }, this.intervalDuration);
    }, 8000);

    // Set a timeout to stop the interval after the total duration
    setTimeout(() => {
      if (this.state.isRunning) {
        this.stop();
      }
    }, this.duration);
  }

  stop() {
    clearInterval(this.interval);
    this.setState({ isRunning: false });
  }

  reset() {
    const { currentChoice } = this.state;
    const { updateWinners } = this.props;
    updateWinners(currentChoice);
    clearInterval(this.interval);
    this.setState({ isRunning: false, currentChoice: {} });
  }

  pickChoice() {
    const { items, winners } = this.props;
    let choice;
    do {
      choice = items[Math.floor(Math.random() * items.length)];
    } while (
      winners.some(
        (winner) => JSON.stringify(winner) === JSON.stringify(choice)
      )
    );
    return choice;
  }

  setChoice() {
    this.setState({ currentChoice: this.pickChoice() });
  }

  render() {
    const { isRunning, currentChoice } = this.state;

    return (
      <div className="RandomPicker">
        <RandomPickerChoice choice={currentChoice} />
        <RandomPickerControls
          isRunning={isRunning}
          hasChoice={currentChoice.Employee?.trim().length > 0}
          start={this.start}
          stop={this.stop}
          reset={this.reset}
        />
      </div>
    );
  }
}

RandomPicker.propTypes = {
  items: PropTypes.array,
};

class RandomPickerChoice extends PureComponent {
  render() {
    const { choice } = this.props;
    return (
      <div className="RandomPicker__choice">
        <span className="RandomPicker__choiceItem">
          {choice.Employee}
          <br />
          {choice.Position}
          <br />
          {choice.Department}
        </span>
      </div>
    );
  }
}

RandomPickerChoice.propTypes = {
  choice: PropTypes.object,
};

class RandomPickerControls extends PureComponent {
  render() {
    const { isRunning, hasChoice, start, stop, reset } = this.props;

    return (
      <div className="RandomPicker__controls">
        <button
          className={`RandomPicker__button ${
            isRunning && "RandomPicker__button--stop"
          }`}
          onClick={isRunning ? stop : start}
        >
          {isRunning ? "stop" : "start"}
        </button>
        <button
          disabled={isRunning || !hasChoice}
          className="RandomPicker__button RandomPicker__button--reset"
          onClick={reset}
        >
          Exclude
        </button>
      </div>
    );
  }
}

RandomPickerControls.propTypes = {
  isRunning: PropTypes.bool,
  hasChoice: PropTypes.bool,
  start: PropTypes.func,
  stop: PropTypes.func,
  reset: PropTypes.func,
  updateWinners: PropTypes.func,
};

export default RandomPicker;
